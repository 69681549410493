import { createMuiTheme } from "@material-ui/core/styles"

const defaultTheme = createMuiTheme({
  palette: {
    white: "#fff",
    black: "#000",
    mainColor: "#ebebeb",
    backgroundLight: "#ebebeb",
    lightText: "#3f51b540",
  },
})

const theme = createMuiTheme({
  ...defaultTheme,
  typography: {
    button: {
      textTransform: "none",
    },
    h2: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },

  customTheme: {
    responsive: {
      display: "block",
      [defaultTheme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          scrollBehavior: "smooth",
        },

        section: {
          borderBottom: `solid 1px #eaeaea`,
          marginBottom: "3rem",
          paddingBottom: "1rem",
        },
      },
    },
  },
})

export default theme
