import { useEffect, useState } from "react"

export default function useKeyboardShortcuts(
  pressedKey = "",
  ref = null,
  callback = null
) {
  const [isShiftKeyOn, setIsshitKeyOn] = useState(false)


  useEffect(() => {
    const handleKeyboard = props => {
      const { repeat, shiftKey, key } = props
      if (repeat) return
      shiftKey ? setIsshitKeyOn(true) : setIsshitKeyOn(false)
      if (ref && shiftKey && key === pressedKey) {
        ref.current.click()
      }
      if (callback && shiftKey && key === pressedKey) callback()
    }

    document.addEventListener("keydown", handleKeyboard)
    document.addEventListener("keyup", handleKeyboard)
    return () => {
      document.removeEventListener("keydown", handleKeyboard)
      document.removeEventListener("keyup", handleKeyboard)
    }
  }, [pressedKey, ref, callback])

  return { isShiftKeyOn }
}
