import React from "react"
import { Typography, useMediaQuery } from "@material-ui/core"
import { CarouselProvider, Slider, Slide, Image } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import euPatentOfficeImg from "../../assets/clients/epo.png"
import europarlImg from "../../assets/clients/europarl.png"
import evonikLogoImg from "../../assets/clients/evoniklogo.svg"
import porphyrioLogoImg from "../../assets/clients/porphyrio.png"
import labs343 from "../../assets/clients/343.png"
import euroComission from "../../assets/clients/eurocomission.svg"
import johnson from "../../assets/clients/johnson.svg"
import payconiq from "../../assets/clients/payconiq.png"

export default function CarouselBlock({ classes }) {
  const onlyDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  const clientImages = [
    {
      img: euroComission,
      desc: "European Comission",
    },
    {
      img: johnson,
      desc: "Johnson & Johnson",
    },
    {
      img: payconiq,
      desc: "Payconiq",
    },
    {
      img: labs343,
      desc: "343 Labs Berlin",
    },

    {
      img: europarlImg,
      desc: "European Parliament",
    },
    {
      img: porphyrioLogoImg,
      desc: "Porphyrio",
    },
    {
      img: euPatentOfficeImg,
      desc: "European Patent Office",
    },
    {
      img: evonikLogoImg,
      desc: "Evonik",
    },
  ]
  return (
    <section id="clients" className="carousel">
      <CarouselProvider
        hasMasterSpinner="true"
        totalSlides={8}
        naturalSlideHeight={75}
        naturalSlideWidth={onlyDesktop ? 155 : 200}
        isPlaying="true"
        visibleSlides={onlyDesktop ? 3 : 1}
        infinite="tue"
        step={1}
      >
        <Typography id="ourClients" align="center" variant="h3" component="h2">
          Our Clients
        </Typography>
        <Slider className={classes.slider} aria-labelledby="ourClients">
          <ul aria-labelledby="ourClients">
            {clientImages.map((img, idx) => (
              <Slide index={idx} className={classes.slide}>
                <Image
                  className="image-carousel"
                  src={img.img}
                  alt={img.desc}
                />
              </Slide>
            ))}
          </ul>
        </Slider>
        {/* <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext> */}
      </CarouselProvider>
    </section>
  )
}
