import React from "react"
import { Typography, makeStyles, Container } from "@material-ui/core"
import linkedinIcon from "../../../assets/linkedin-icon.png"

const useStyles = makeStyles(theme => ({
  section: {
    background: "#3e51b5",
    color: "#fff",
    marginBottom: "0",
    borderBottom: "solid 5px #3f50ad",
    padding: "2rem 0",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "normal",
    },
    "& article": {
      "& iframe": {
        borderRadius: "10px",
      },
    },
  },
  serviceLinks: {
    textDecoration: "none",
    color: theme.palette.black,
  },
  text: {
    textAlign: "justify",
  },
  justify: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
}))

export default function MainServices() {
  const classes = useStyles()
  return (
    <section id="articles" className={classes.section}>
      <Container className={classes.container}>
        <a
          href="https://www.linkedin.com/in/javiersmithpaterson/"
          className={`${classes.link} after-link`}
        >
          <img className="linkedin-icon" src={linkedinIcon} alt="linkedin" />
        </a>

        <a
          href="https://www.linkedin.com/in/javiersmithpaterson/"
          className={`${classes.link} after-link`}
        >
          <Typography variant="h6" component="h3" align="center">
            Articles on Linkedin
          </Typography>
        </a>
      </Container>
    </section>
  )
}
