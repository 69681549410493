import React, { useRef } from "react"
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import KeyboardShortcut from "../../../keyboardShortcuts/KeyboardShortcut"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
  },
  summary: {
    padding: "0",
  },
  icon: {
    margin: "0 auto",
  },
  menu: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
    "& ul": {
      listStyle: "none",
      textAlign: "center",
      margin: "0",
      padding: "0",
      [theme.breakpoints.up("md")]: {
        display: "inline-flex",
        width: "auto",
      },
      "& li": {
        margin: "0 10px",
        position: "relative",
        "& a": {
          textTransform: "uppercase",
          fontSize: "0.9em",
        },
      },
    },
  },
}))

function NavMenu() {
  const classes = useStyles()
  const homeRef = useRef(null)
  const serviceRef = useRef(null)
  const articleRef = useRef(null)
  const clientRef = useRef(null)
  // useKeyboardShortcuts("1", homeRef)
  // useKeyboardShortcuts("S", serviceRef)

  return (
    <nav className={classes.menu} aria-label="Main">
      <ul>
        <li>
          <Button label="to Homepage" href="/#top" ref={homeRef}>
            Home
          </Button>
          <KeyboardShortcut pressedKey="A" refElement={homeRef} />
        </li>
        <li>
          <Button label="to articles section" href="#services" ref={serviceRef}>
            Services
          </Button>
          <KeyboardShortcut pressedKey="S" refElement={serviceRef} />
        </li>
        <li>
          <Button label="to articles section" href="#articles" ref={articleRef}>
            Articles
          </Button>
          <KeyboardShortcut pressedKey="D" refElement={articleRef} />
        </li>
        <li>
          <Button label="to client's section" href="#clients" ref={clientRef}>
            Clients
          </Button>
          <KeyboardShortcut pressedKey="F" refElement={clientRef} />
        </li>
        <li>
          <Button label="to About Us section" href="#about">
            About Us
          </Button>
        </li>
        <li>
          <Button label="to Contact Form" href="#contact">
            Contact Us
          </Button>
        </li>
      </ul>
    </nav>
  )
}

function MobileMenu() {
  const classes = useStyles()
  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        className={classes.summary}
        aria-controls="menuNav-content"
        id="menuNav-header"
      >
        <MenuIcon className={classes.icon} />
      </AccordionSummary>
      <AccordionDetails>
        <NavMenu />
      </AccordionDetails>
    </Accordion>
  )
}

export default function Navigation() {
  const showMobileMenu = useMediaQuery(theme => theme.breakpoints.up("sm"))

  return showMobileMenu ? <NavMenu /> : <MobileMenu />
}
