import React from "react"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import YouTubeIcon from "@material-ui/icons/YouTube"
import RoomIcon from "@material-ui/icons/Room"
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  Link,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  ...theme.customTheme,
  root: {
    background: theme.palette.backgroundLight,
    paddingTop: theme.spacing(2),
  },
  logo: {
    alignSelf: "center",
  },
  iconSize: {
    "& svg": {
      fontSize: "2em",
    },
  },
}))

function AddressBox({ children, city }) {
  return (
    <Box className="address-box">
      <Typography variant="h6" component="h3">
        <span className="visuallyHidden">Our Address in </span>
        {city}
      </Typography>
      <address className="flex">
        <div className="address-icon">
          <RoomIcon />
        </div>

        {children}
      </address>
    </Box>
  )
}
export default function Contact({ data }) {
  const classes = useStyles()
  const onlyDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  return (
    <div id="contact" className={classes.root}>
      <Typography className="visuallyHidden" variant="h2" align="center" pb={4}>
        Contact
      </Typography>

      <Box>
        <Box className={classes.responsive} justifyContent="space-between">
          {/* <AddressBox city="Berlin">
            <div>
              Krummerstr 63,<br></br>
              Charlottenburg, 10627,<br></br>
              {onlyDesktop && "Berlin, Germany. "}
            </div>
          </AddressBox> */}
          <AddressBox city="Belgium">
            <div>
              Boomgaardstraat 348,<br></br>
              Antwerp, 2600,<br></br>
              {onlyDesktop && "Belgium"}
            </div>
          </AddressBox>
          {/* <Logo customClass={classes.logo} /> */}
          <Box className="contactInfo">
            <nav aria-label="Contact Information">
              <ul
                className={
                  onlyDesktop
                    ? `clear ${classes.iconSize}`
                    : `between clear ${classes.iconSize}`
                }
              >
                <li>
                  <Link href="tel:+32 487 72 76 89" title="Phone">
                    <PhoneIcon />
                    {onlyDesktop && (
                      <Typography component="span">+32 487 72 76 89</Typography>
                    )}
                  </Link>
                </li>
                <li>
                  <Link href="mailto:info@jspmultimedia.com" title="Email">
                    <EmailIcon />
                    {onlyDesktop && (
                      <Typography component="span">
                        info@jspmultimedia.com
                      </Typography>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.linkedin.com/company/jspmultimedia.com"
                    title="Linkedin"
                  >
                    <LinkedInIcon />
                    {onlyDesktop && (
                      <Typography component="span">Linkedin</Typography>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.youtube.com/jspmultimedia1"
                    title="Youtube"
                  >
                    <YouTubeIcon />
                    {onlyDesktop && (
                      <Typography component="span">Youtube</Typography>
                    )}
                  </Link>
                </li>
              </ul>
            </nav>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
